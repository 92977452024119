<template>
  <!--服务项目配置-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="客户名称:">
                    <!--<el-input-->
                    <!--  clearable-->
                    <!--  v-model="where.keyword"-->
                    <!--  placeholder="请输入客户名称"/>-->
                    <el-select
                        clearable
                        v-model="where.channel_id"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option v-for="(item) in client" :label="item.channel_name + item.channel_type" :value="parseInt(item.id)"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">
                <el-button class="custom-button" style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;" @click="openEdit(null)" v-auths="[`${$config.uniquePrefix}operate:serviceItemConfiguration:save`]">
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">添加项目配置</span>
                </el-button>
                <el-button class="custom-button" style="background: #F56C6C!important;border-color: #FEA837!important;color: #FFFFFF!important;" @click="openC(null)" >
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">C端服务项配置</span>
                </el-button>
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >

              <template v-slot:channel_name= "{ scope }">
                <span>{{scope.row.channel_name}}</span>
                <span>{{scope.row.channel_tags}}</span>
              </template>

              <template v-slot:items_attribute= "{ scope }">
                <div v-for="(item) in dict.type.detailproperty">
                  <span v-if="scope.row.items_attribute == item.value">{{item.label}}</span>
                </div>
              </template>

              <template v-slot:template_pattern_name= "{ scope }">
                <div>
                  <span style="margin-right: 10px;">{{scope.row.template_pattern_name}}</span>
                </div>
              </template>

              <template v-slot:caseload_template_name= "{ scope }">
                <div>
                  <span>案件地模板：</span>
                  <span style="margin-right: 10px;">{{scope.row.caseload_template_name}}</span>
                </div>
                <div v-if="scope.row.destination_template_uniques != ''">
                  <span>目的地模板：</span>
                  <span style="margin-right: 10px;">{{scope.row.destination_template_name}}</span>
                </div>
              </template>


              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" @click="openEdit(scope.row)" v-auths="[`${$config.uniquePrefix}operate:serviceItemConfiguration:put`]">编辑</el-link>
                <!--<el-link :underline="false" type="danger" v-auths="[`${$config.uniquePrefix}custom:deactivate`]" @click="CreatePenaltyOrder(1)">处罚</el-link>-->
                <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">
                  <el-link slot="reference" :underline="false" style="margin-left: 10px;" v-auths="[`${$config.uniquePrefix}operate:serviceItemConfiguration:delete`]">删除</el-link>
                </el-popconfirm>
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <!--项目配置弹窗组件-->
    <projectConfiguration-edit v-if="showEdit === true" :data="current" :dict="dict" :visible.sync="showEdit" @done="getList"/>

    <el-dialog
        center
        title="C端服务项配置"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClosehandleClose">
        <div>
          <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <div style="margin-top: 10px;">
            <el-checkbox-group v-model="serve_type">
              <el-checkbox v-for="(item,index) in ServicesAvailable" :key="index" :label="item.serial_number" @change="selectchange">{{item.label}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div slot="footer">
          <el-button
              @click="quxiao()">取消
          </el-button>
          <el-button
              style="margin-left: 20px;"
              type="primary"
              :loading="loading"
              @click="queding()">确定
          </el-button>
        </div>
    </el-dialog>

  </div>
</template>

<script>
//引入项目配置弹窗组件
import ProjectConfigurationEdit from './components/projectConfiguration-edit.vue'

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'

import Vue from "vue";

// 引入的接口
import {
  consumer_services_config,
  Delservices_config,
  getservices_config,
  saveconsumer_services_config
} from "@/api/operate";
//服务项目接口
import {getget_services_available} from '@/api/yunli'
// 客户下拉
import {get_select} from "@/api/custom";

export default {
  // 组件生效
  components: {
    ProjectConfigurationEdit,
    PackageSortElement,
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "客户名称",
          // type: "index",
          // width: "70"
          prop: "channel_name",
          slot:'channel_name',
          isShow: true
        },
        {
          label: "项目名称",
          // type: "index",
          // width: "70"
          prop: "items_name",
          isShow: true
        },
        {
          label: "项目属性",
          prop: "items_attribute",
          slot: 'items_attribute',
          isShow: true
        },
        {
          label: "模板来源",
          prop: "template_pattern_name",
          slot: 'template_pattern_name',
          isShow: true
        },
        {
          label: "模板项",
          prop: "caseload_template_name",
          slot: 'caseload_template_name',
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      current:null,
      showEdit:false,

      //字典
      dict: {
        type:{}
      },

      dialogVisible:false,
      //服务项目
      ServicesAvailable:[],

      checkAll:false,
      serve_type:[],

      // 客户
      client:[],

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '运营字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

    //调用获取客户信息列表方法
    this.getList();

    this.getFuwuxiangmu();

    this.getKehu();

  },

  methods:{
    //获取服务项目
    getFuwuxiangmu(){
      getget_services_available().then(res => {
        this.ServicesAvailable = res.data;
      })
    },

    //获取客户名称列表
    getKehu(){
      get_select().then(res => {
        this.client = res.data;
      })
    },

    // 获取列表
    getList(){
      getservices_config(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 删除
    del(row){
      console.log(row);
      // this.$utils.confirm("确定要删除吗？", function () {
      //   // 执行一些需要的逻辑
      //   console.log('点击确定')
      // }, "确定", 'warning');
      Delservices_config(row.id).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList();
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    //打开编辑弹窗
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },

    // 点击C端服务配置
    openC(){
      consumer_services_config().then(res => {
        console.log(res)
        this.serve_type = res.data;
        if(this.serve_type.length == this.ServicesAvailable.length){
          this.checkAll = true
        }else {
          this.checkAll = false;
        }
        this.dialogVisible = true;
      })

    },
    handleClosehandleClose(){
      this.dialogVisible = false;
    },
    //取消
    quxiao(){
      this.dialogVisible = false;
    },
    // 确定
    queding(){
      let data = {
        items: this.serve_type
      }
      saveconsumer_services_config(data).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.dialogVisible = false;
        }else {
          this.$message.success(res.msg);
        }
      })
    },

    //点击服务类型全选
    handleCheckAllChange(){
      if(this.checkAll == true){
        this.serve_type = [];
        this.ServicesAvailable.forEach(item => {
          this.serve_type.push(item.serial_number)
        })
      }else {
        this.serve_type = [];
      }
    },
    // 服务类型选中值
    selectchange(){
      // 如果选择的数组长度等于总共的服务类型长度全选为true
      if(this.serve_type.length == this.ServicesAvailable.length){
        this.checkAll = true
      }else {
        this.checkAll = false;
      }
    },


  }

}
</script>

<style lang="scss" scoped>

</style>
